export const TEXT_TYPE = 'text'
export const NUMERIC_TYPE = 'numeric'
export const LIBELLE_TYPE = 'libelle'
export const DATE_TYPE = 'date'
export const TEMPLATE_TYPE = 'template'
export const COMPONENT_TYPE = 'component'
export const BOOLEAN_TYPE = 'boolean'

export const ORDER_ASC = 'asc'
export const ORDER_DESC = 'desc'

export const USER_CURRENT_LANG = 'fr'
