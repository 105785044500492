<template>
  <a
    href="javascript:void(0)"
    @click="toggle"
    class="sort"
  >
    <span> <slot /> </span>
    <span
      class="icon"
      v-if="visible || iconVisible"
      @mouseover="iconVisible = true"
      @mouseleave="iconVisible = false"
    >
      <i
        class="icofont icofont-curved-up icofont-2x"
        v-if="order"
      />
      <i
        class="icofont icofont-curved-down icofont-2x"
        v-else
      />
    </span>
  </a>
</template>

<script>
import { ORDER_ASC, ORDER_DESC } from './dataType'

export default {
  name: 'Sort',
  props: {
    name: { type: String, required: true },
    function: {type: Function},
    type: String,
    index: Number,
    active: Number
  },
  data () {
    return {
      order: false,
      iconVisible: false
    }
  },
  methods: {
    toggle () {
      this.order = !this.order
      this.$emit('sorting', { by: this.name, type: this.type, index: this.index, order: this.order ? ORDER_ASC : ORDER_DESC, function: this.function })
    }
  },
  computed: {
    visible () {
      return this.index === this.active
    }
  }
}
</script>

<style lang="scss" scoped>
  .sort{
    width: 100%;
    color: #212529;
    font-size: 12px;
    &:hover{
      text-decoration: none;
      cursor: pointer;
    }
    .icon{
      float: right;
    }
  }
</style>
